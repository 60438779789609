<template>
  <section class="competence" :id="'competence' + competenceIndex" :class="{ valid: isValid }">
    <header>
      <span class="category">
        <img :src="valueCopy.iconUrl" alt="icoon" />
        {{ valueCopy.category }}
      </span>
      <h2 class="h2">
        {{ valueCopy.competence }}
        <span v-if="isValid">
          <img src="/icons/check_circle-24px.svg" alt="Checkmark" />
        </span>
      </h2>

      <p v-if="valueCopy.competenceSubtitle" v-text="valueCopy.competenceSubtitle" class="p-large"></p>
    </header>

    <div class="levels-wrap">
      <ul v-if="requirements" class="levels-list" ref="scroller" @scroll="onScroll">
        <li
          v-for="(requirement, index) in requirements"
          :key="index"
          :class="{ active: valueCopy.level - 1 === index }"
        >
          <h3>
            Niveau {{ index + 1 }}
            <span
              class="level-up"
              v-if="valueCopy.level - 1 === index && parseInt(valueCopy.level) > parseInt(valueCopy.prevLevel)"
              >+{{ parseInt(valueCopy.level) - parseInt(valueCopy.prevLevel) }} ⭐</span
            >
          </h3>
          <p v-html="formatRequirement(requirement)"></p>
          <div>
            <button v-on:click="showExamples(index)" class="btn-secondary">Bekijk de voorbeelden</button>
          </div>

          <div class="btns">
            <button
              v-if="valueCopy.level - 1 !== index"
              v-on:click="valueCopy.level = index + 1"
              class="btn btn-action"
            >
              Zet als niveau
            </button>
            <button v-else v-on:click="valueCopy.level = ''" class="btn btn-action btn-delete-level">
              Verwijder dit niveau
            </button>
          </div>
        </li>
      </ul>
      <button class="btn-nav btn-prev" :disabled="!hasPrev" @click="prev">
        <img src="/icons/navigate_before-24px.svg" alt="Previous" />
      </button>
      <button class="btn-nav btn-next" :disabled="!hasNext" @click="next">
        <img src="/icons/navigate_next-24px.svg" alt="Previous" />
      </button>
    </div>

    <div v-if="valueCopy.prevLevel" class="prev-level">Vorig niveau: {{ valueCopy.prevLevel }}</div>

    <h4>Voorbeelden</h4>
    <div class="textarea-wrap">
      <textarea
        v-model="valueCopy.examples"
        @change="updateMatrix(`D${competenceIndex + 5}`, value.examples)"
      ></textarea>
      <button
        v-if="!valueCopy.examples"
        @click="
          setExamplesText('Gelijk aan vorig jaar')
          updateMatrix(`D${competenceIndex + 5}`, value.examples)
        "
      >
        <img src="/icons/create-24px.svg" />Gelijk aan vorig jaar
      </button>
    </div>

    <h4 :class="{ expanded: !commentsHidden }">
      <button @click="toggleComments">Opmerkingen <img src="/icons/expand_more-24px.svg" /></button>
    </h4>
    <textarea
      v-model="valueCopy.comments"
      v-show="!commentsHidden"
      @change="updateMatrix(`E${competenceIndex + 5}`, value.comments)"
    ></textarea>

    <button type="button" v-on:click="scrollToNext" class="btn btn-next-competence">
      <span v-if="competenceIndex === 5 || competenceIndex === 14">Volgende waarde</span>
      <span v-else-if="competenceIndex === 21">Nog 3 vragen te gaan</span>
      <span v-else>Volgende competentie</span>
      <img src="/icons/arrow_downward-24px.svg" />
    </button>

    <modal v-if="showModal" @close="showModal = false" ref="modal">
      <template v-slot:body>
        <markdown-it-vue class="md-body" :content="examplesData[activeExamplesLevel]" />
      </template>
    </modal>
  </section>
</template>

<script>
import GapiHelper from '@/helpers/GapiHelper'
import MarkdownItVue from 'markdown-it-vue'
import { convertGoogleDocumentToJson, convertJsonToMarkdown } from '@/helpers/DocsParser'
import Modal from '@/components/Modal.vue'
import debounce from 'lodash.debounce'

export default {
  props: ['value', 'requirements', 'competence-index'],
  data() {
    return {
      valueCopy: this.value, // used to be able to update prop 'value' in the parent
      showModal: false,
      activeExamplesLevel: 0,
      examplesData: ['', '', '', '', ''],
      commentsHidden: !this.value.comments,

      // scroll stuff
      activeItem: 0,
      scrollValue: 0,
      maxScroll: 0,
    }
  },

  computed: {
    isValid() {
      return this.$parent.validCompetences[this.competenceIndex]
    },
    hasPrev() {
      return this.scrollValue > 0
    },
    hasNext() {
      return this.scrollValue < this.maxScroll
    },
    el() {
      return this.$refs.scroller
    },
  },

  watch: {
    value() {
      this.valueCopy = this.value // create two-way binding
    },
    'valueCopy.level'(level) {
      console.log('changed level', level)
      this.updateMatrix(`C${this.competenceIndex + 5}`, level)
    },
  },

  mounted() {
    this.setScrollValues()

    if (this.valueCopy.level) {
      this.scrollToActiveLevel()

      // after scroll: set scrollValue, so computed 'hasPrev' is correctly calculated when level is already set > 1
      setTimeout(() => {
        this.scrollValue = this.$refs.scroller.scrollLeft
      }, 100)
    }
  },

  methods: {
    formatRequirement(value) {
      value = value.replace(/Vereisten\n/, '<strong>Vereisten</strong><br>')
      value = value.replace(/Impact\n/, '<strong>Impact</strong><br>')
      value = value.replace(/([a-z])(\/)([a-z])/g, '$1 / $3') // make things/like/this wrap nice

      return value
    },

    async getDoc(levelIndex) {
      const obj = await GapiHelper.getDoc(this.competenceIndex, levelIndex)
      // console.log(obj);
      // console.log(convertGoogleDocumentToJson(obj))

      const documentInJson = convertGoogleDocumentToJson(obj)
      //console.log(documentInJson);
      return convertJsonToMarkdown(documentInJson)
      // console.log(this.examplesMarkdown);
    },

    scrollToNext() {
      const topValue =
        this.$el.getBoundingClientRect().top + this.$el.getBoundingClientRect().height + window.pageYOffset - 60 // 60 = statusbar height
      window.scroll({ top: topValue, left: 0, behavior: 'smooth' })
    },

    scrollToActiveLevel() {
      if (this.valueCopy.level) {
        this.activeItem = parseInt(this.valueCopy.level) - 1
      }

      this.goTo(this.activeItem)
    },

    async updateMatrix(cell, value) {
      await GapiHelper.updateMatrixValue(this.$route.params.sheetId, cell, value)
    },

    async showExamples(levelIndex) {
      this.activeExamplesLevel = levelIndex
      this.showModal = true

      if (!this.examplesData[this.activeExamplesLevel]) {
        // console.log('get doc from server')
        const examplesMarkdown = await this.getDoc(levelIndex)
        this.examplesData[this.activeExamplesLevel] = examplesMarkdown
      }

      // needed because of v-if on modal
      this.$nextTick(() => {
        this.$refs.modal.contentVisible = true
      })
    },

    toggleComments() {
      this.commentsHidden = !this.commentsHidden
    },

    // carrousel stuff
    getScrollValue(index) {
      return this.el.children[index].offsetLeft - this.el.children[0].offsetLeft
    },
    setScrollValues() {
      this.maxScroll = this.el.scrollWidth - this.el.offsetWidth
      this.scrollValues = []

      for (let i = 0; i < this.el.children.length; i++) {
        this.scrollValues.push(this.getScrollValue(i))
      }
    },
    onScroll: debounce(function (event) {
      this.scrollValue = event.target.scrollLeft

      let activeItem = 0

      for (let i = 0; i < this.scrollValues.length; i++) {
        if (this.scrollValue === this.scrollValues[i] || this.scrollValue < this.scrollValues[i]) {
          activeItem = i
          break
        }
      }

      this.activeItem = activeItem
    }, 100),
    goTo(index) {
      const offsetLeft = this.scrollValues[index]
      this.el.scroll({ top: 0, left: offsetLeft, behavior: 'smooth' })
    },

    prev() {
      this.goTo(this.activeItem - 1)
    },

    next() {
      this.goTo(this.activeItem + 1)
    },

    setExamplesText(text) {
      this.valueCopy.examples = text
    },
  },

  components: {
    MarkdownItVue,
    Modal,
  },
}
</script>

<style scoped lang="scss">
.competence {
  min-height: 100vh;
  padding-bottom: 200px;
}

.category {
  background: #41453b;
  color: #fff;
  padding: 0 7px;
  line-height: 26px;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 700;
  font-size: 15px;
  padding-left: 29px;
  position: relative;
  margin-top: 40px;

  img {
    width: 17px;
    position: absolute;
    left: 7px;
    top: 4px;
  }
}

h2 {
  img {
    width: 30px;
    transform: translateY(5px);
  }
}

header {
  p {
    margin-bottom: 0;
  }
}

h3 {
  font-size: 28px;
  text-align: center;
  margin: 0;
}

.levels-wrap {
  position: relative;
}

.level-up {
  position: absolute;
  top: 0px;
  right: 0;
  color: #84bb26;
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0 0 0 3px;
  padding: 2px 8px;
  font-size: 24px;
}

.btn-nav {
  position: absolute;
  bottom: 50%;
  transform: translateY(32px);
  width: 50px;
  height: 50px;
  background: #41453b;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: backgroud-color 0.2s ease, opacity 0.2s ease;

  &:disabled {
    opacity: 0;
  }

  img {
    width: 36px;
  }
}

.btn-prev {
  left: -70px;
}

.btn-next {
  right: -70px;
}

.levels-list {
  padding-bottom: 10px;
  padding-left: 0;
  padding-top: 30px;
  list-style: none;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

ul {
  margin: 0;
}

li {
  scroll-snap-align: start;
  white-space: pre-line;
  border-radius: 3px;
  background: #fff;
  padding: 20px;
  margin: 0 15px;
  border: 3px dashed;
  flex-basis: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

p:not(.p-large) {
  font-size: 15px;
  margin-bottom: 20px;
}

.btn-secondary {
  margin-bottom: 40px;
}

.btn-delete-level {
  background: transparent;
  color: grey;
  font-size: 15px !important;
}

.btns {
  margin-top: auto;
  margin-bottom: 20px;
  text-align: center;

  .btn {
    line-height: 50px;
    padding: 0 25px;
    font-size: 18px;
  }
}

.active {
  border-color: #0093d8;
  border-style: solid;
  background: rgb(193, 235, 255);
  position: relative;

  h3 {
    color: #0093d8;
  }

  &:before {
    content: ' ';
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('/images/pointer.svg');
    background-repeat: no-repeat;
    background-size: 18px 28px;
    width: 18px;
    height: 28px;
  }
}

.prev-level {
  margin-top: 10px;
}

h4 {
  margin-bottom: 5px;

  &,
  button {
    font-weight: 700;
    font-size: 16px;
  }

  button {
    background: transparent;
    color: inherit;
    padding: 0;
  }

  img {
    position: absolute;
  }

  &.expanded {
    img {
      transform: rotate(180deg);
    }
  }
}

.textarea-wrap {
  position: relative;

  button {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background: #efefef;
    line-height: 24px;
    padding: 0 5px 0 28px;
    border-radius: 3px;
    transition: all 0.2s ease;

    &:hover {
      background: #ccc;
    }

    img {
      width: 20px;
      position: absolute;
      top: 2px;
      left: 5px;
    }
  }
}

.btn-next-competence {
  margin-top: 40px;
}

.valid {
  .btn-next-competence {
    background: #84bb26;

    &:hover {
      background: #679323;
    }
  }
}
</style>
