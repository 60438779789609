<template>
  <div class="container container-fullscreen">
    <img src="/images/logo-nm.svg" class="logo" alt="logo" />

    <div class="hero">
      <h1>Stap 2: Vul de link naar jouw Niveaumatrix in</h1>

      <p class="p-large">Kopieer de url van jouw Niveaumatrix en vul deze hieronder in.</p>

      <div class="input-wrap">
        <input type="text" class="input" v-model="sheetId" @input="validateInput" />
        <img src="/icons/cancel-24px.svg" v-if="hasError" alt="Checkmark" />
        <img src="/icons/check_circle-24px.svg" v-else-if="sheetId" alt="Checkmark" />
      </div>

      <div>
        <button class="btn-compare" :class="{ expanded: !extraFieldsHidden }" @click="toggleExtraFields">
          Vergelijken met een vorige Niveaumatrix
          <img src="/icons/expand_more-24px.svg" />
        </button>
        <div v-show="!extraFieldsHidden" class="compare-block">
          <p>
            De Niveaumatrix versies v2020.1 en hoger kunnen worden getoond in de reader, als: je zelf de mogelijke
            aanpassingen van Chris/Laurens hebt doorgevoerd in je niveaumatrix én je geen extra rijen of kolommen hebt
            toegevoegd in je niveaumatrixsheet.
          </p>
          <div class="input-wrap">
            <input type="text" class="input" v-model="compareSheetId" @input="validateInputCompareSheet" />
            <img src="/icons/cancel-24px.svg" v-if="hasErrorCompareSheet" alt="Checkmark" />
            <img src="/icons/check_circle-24px.svg" v-else-if="compareSheetId" alt="Checkmark" />
          </div>
        </div>
      </div>

      <button type="button" v-on:click="navigate" :disabled="!sheetId || hasError" class="btn btn-next-competence">
        Naar de niveaumatrix <img src="/icons/arrow_forward-24px.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sheetId: '',
      compareSheetId: '',
      hasError: false,
      hasErrorCompareSheet: false,
      extraFieldsHidden: true,
    }
  },

  methods: {
    getSheetId(id) {
      // valid url: https://docs.google.com/spreadsheets/d/10obNYivBKZpZT8eqElnGIX5vV_LsTm-UJ6Nsk3jusVk/edit#gid=430828337
      const matches = new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)').exec(id)
      return matches && !!matches[1] ? matches[1] : null
    },
    validateInput() {
      const id = this.getSheetId(this.sheetId)
      this.hasError = !id
    },
    validateInputCompareSheet() {
      const id = this.getSheetId(this.compareSheetId)
      this.hasErrorCompareSheet = !id
    },
    navigate() {
      const id = this.getSheetId(this.sheetId)
      const compareId = this.getSheetId(this.compareSheetId)
      if (compareId) {
        this.$router.push({
          name: 'Reader',
          params: { sheetId: id },
          query: { compareSheetId: compareId },
        })
      } else {
        this.$router.push({ name: 'Reader', params: { sheetId: id } })
      }
    },
    toggleExtraFields() {
      this.extraFieldsHidden = !this.extraFieldsHidden
    },
  },
}
</script>

<style scoped lang="scss">
.input {
  display: block;
  width: 100%;
}

.input-wrap {
  margin-top: 20px;
  padding-right: 30px;
  position: relative;

  img {
    position: absolute;
    right: 0;
    top: 8px;
  }
}

.btn-compare {
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  background: transparent !important;
  color: inherit !important;
  padding: 0;

  img {
    position: absolute;
  }

  &.expanded {
    img {
      transform: rotate(180deg);
    }
  }
}

.compare-block {
  p {
    margin-top: 10px;
    text-align: left;
    width: 95%;
  }
}
</style>
