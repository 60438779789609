<template>
  <transition name="modal">
    <div class="modal-mask" @click="$emit('close')" :class="{'center-content': !contentVisible}">
      <div class="modal-wrapper" v-if="contentVisible">
        <div class="modal-container" @click.stop="cancelBubbling">
          <div class="modal-body">
            <button  @click="$emit('close')">
              <img src="/icons/clear-24px.svg" />
            </button>
            <slot name="body">
              default body
            </slot>
          </div>
        </div>
      </div>
      <Spinner v-else color="#FFF" />
    </div>
  </transition>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
  data() {
    return {
      contentVisible: false
    }
  },
  methods: {
    cancelBubbling() {
      // this setup makes sure when clicking on the content of the modal, the modal is not closed because of modal-mask 
      return true;
    }
  },
  components: {
    Spinner
  }
}
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.2s ease;
  padding: 30px 0;

  &.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s  ;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  position: relative;

  button {
    background: transparent;
    border: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 20px;

    img {
      width: 30px;
    }
  }

  h1 {
    margin-top: 0;
    padding-right: 30px; // make space for close button
  }
}




/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>