<template>
  <div class="start">
    <div class="intro-wrap-wrap">
      <div class="intro-wrap">
        <p v-html="formatIntroText(matrixExplanation[0][0])" class="intro p-large"></p>
      </div>
    </div>

    <section class="first-question-wrap" id="question1">
      <div class="balloon balloon-left">
        <h2 v-text="matrixOtherValues[0].question"></h2>
        <p v-text="matrixOtherValues[0].questionSubtitle" class="p-large"></p>
      </div>
      <div class="balloon balloon-right">
        <textarea
          v-model="matrixOtherValues[0].answer"
          @change="updateMatrix('C4', matrixOtherValues[0].answer)"
        ></textarea>
        <button @click="scrollToEl('explanation')" class="btn btn-next-competence">
          <span data-v-4e4eb50e="">Verder</span><img src="/icons/arrow_downward-24px.svg" />
        </button>
      </div>
    </section>

    <div class="explanation" id="explanation">
      <h2>Voordat je aan de niveaus begint...</h2>
      <p class="p-large">
        Alles wat je invult wordt automatisch opgeslagen in je Niveaumatrix spreadsheet. Het kan echter zijn dat je deze
        moet refreshen om de aanpassingen te zien.
      </p>
      <div v-html="formatText(matrixExplanation[2][0])"></div>
      <div v-html="formatText(matrixExplanation[4][0])"></div>
      <div v-html="formatText(matrixExplanation[6][0])"></div>
      <button @click="scrollToEl('category1')" class="btn btn-next-competence">
        <span data-v-4e4eb50e="">Aan de slag!</span><img src="/icons/arrow_downward-24px.svg" />
      </button>
    </div>
  </div>
</template>

<script>
import GapiHelper from '@/helpers/GapiHelper'
import AppHelper from '@/helpers/AppHelper'

export default {
  props: ['matrix-explanation', 'name', 'matrix-other-values'],
  methods: {
    formatIntroText(value) {
      const qer = this.name ? this.name : `Q'er`
      value = value.replace(/Lieve Q'er\n/, `<h1 class="intro-title">Lieve ${qer},</h1><br>`)
      value = value.replace(/\n(In deze sheet.+)(\n)/, '') // tekst niet relevant in deze reader
      value = value.replace(/Het niveaumatrix team/, `<span class="signature">Het niveaumatrix team</span>`)

      return value
    },
    formatText(value) {
      value = value.replace(/(-.*)/g, '<li>$1</li>')
      value = value.replace(/- /g, '')
      value = value.replace(/<li>/, '<ul><li>')
      value = value.replace(/over impact/, 'over impact<br>') // TODO improve
      value = value.replace(/Bij twijfel/, '</ul>Bij twijfel') // TODO improve
      value = value.replace(/Bij duidelijke impact/, '</ul>Bij duidelijke impact') // TODO improve

      return value
    },
    async updateMatrix(cell, value) {
      await GapiHelper.updateMatrixValue(this.$route.params.sheetId, cell, value)
    },
    scrollToEl(id) {
      const el = document.getElementById(id)
      if (el) {
        AppHelper.scrollToEl(el)
      }
    },
  },
}
</script>

<!-- had some errors with styling <strong> in scoped scss -->
<style lang="scss">
.start {
  $padding-top: 120px;
  display: flex;
  flex-direction: column;
  padding-top: $padding-top;

  p {
    white-space: pre-line;
  }

  .explanation {
    padding-top: 60px;
    font-size: 20px;

    h2 {
      color: #0093d8;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    div {
      margin-top: 30px;
      max-width: 36em;

      &::first-line {
        font-weight: bold;
      }
    }

    ul {
      margin: 0;
      margin-bottom: 10px;
    }

    li {
      margin-top: 10px;
    }

    button {
      margin-top: 40px;
    }
  }

  .intro-wrap-wrap {
    min-height: calc(100vh - #{$padding-top});
    overflow: hidden;
    position: relative;

    // hack for showing shadow with overflow: hidden
    margin: 0 -60px;
    padding: 0 60px;
  }

  .intro-wrap {
    margin-bottom: 600px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      height: 1000px;
      left: 50px;
      top: 100%;
      margin-top: 60px;
      border-left: 2px dashed #0093d8;
    }
  }

  .intro {
    border-radius: 12px;
    box-shadow: 2rem 2rem 5rem rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.6);
    padding: 50px;
  }

  .intro-title {
    font-size: 50px;
    text-transform: uppercase;
    font-family: 'Proxima Nova';
    color: #0093d8;
    margin: 0;
  }

  .signature {
    font-size: 30px;
    font-family: 'Kalam', cursive;
    font-weight: bold;
  }

  h2 {
    font-size: 36px;
    margin: 0;
  }
}
</style>
