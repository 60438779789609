<template>
  <div class="end">
    <section
      class="first-question-wrap"
      :id="`question${index + 2}`"
      v-for="(otherValue, index) in matrixOtherValues"
      :key="index"
    >
      <div class="balloon balloon-left">
        <h2 v-text="otherValue.question"></h2>
        <p v-text="otherValue.questionSubtitle" class="p-large"></p>
      </div>
      <div class="balloon balloon-right">
        <textarea v-model="otherValue.answer" @change="updateMatrix(`C${27 + index}`, otherValue.answer)"></textarea>
        <button
          class="btn btn-next-competence"
          @click="scrollToEl(matrixOtherValues[index + 1] ? `question-${index + 1}` : 'theend')"
        >
          <span v-text="matrixOtherValues[index + 1] ? 'Verder' : 'Dat was het!'"></span
          ><img src="/icons/arrow_downward-24px.svg" />
        </button>
      </div>
    </section>

    <div class="the-end" id="theend">
      <h1>Klaar!</h1>
      <div class="p-large">
        <p>
          Bedank voor het invullen!<br />
          Voordat je even een welverdiende break gaat nemen, nog even het volgende: <br />
        </p>
        <ul>
          <li>
            Er zit een schoonheidsbug in de Niveaumatrix spreadsheet, namelijk: lange voorbeelden lopen over het veld
            met opmerkingen heen. Fix dit door alle cellen met voorbeelden en opmerkingen te selecteren om vervolgens de
            tekst wrapping aan te passen (Format -> Text wrapping -> Wrap).
          </li>
          <li>
            Dubbelcheck de inhoud van de Niveaumatrix spreadsheet, want ja... het blijft software gemaakt door mensen.
          </li>
        </ul>
        <p class="greet">Ciao!</p>
      </div>
    </div>
  </div>
</template>

<script>
import GapiHelper from '@/helpers/GapiHelper'
import AppHelper from '@/helpers/AppHelper'

export default {
  props: ['matrix-other-values'],
  methods: {
    formatText(value) {
      value = value.replace(/(-.*)/g, '<li>$1</li>')
      value = value.replace(/- /g, '')
      value = value.replace(/<li>/, '<ul><li>')

      return value
    },
    async updateMatrix(cell, value) {
      await GapiHelper.updateMatrixValue(this.$route.params.sheetId, cell, value)
    },
    scrollToEl(id) {
      const el = document.getElementById(id)
      if (el) {
        AppHelper.scrollToEl(el)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.the-end {
  padding-top: 60px;
  min-height: 100vh;
}

h1 {
  font-size: 50px;
  text-transform: uppercase;
  font-family: 'Proxima Nova';
  color: #0093d8;
  margin: 0;
}

.greet {
  font-size: 30px;
  font-family: 'Kalam', cursive;
  font-weight: bold;
}
</style>
