<template>
  <div class="category" :id="`category${nr}`">
    <div class="inner">
      <img :src="iconUrl" class="icon" alt="Icoon" />
      <h2 v-text="category.title"></h2>
      <p v-text="category.description"></p>
      <button type="button" v-on:click="scrollToCompetence(firstCompetenceIndex)" class="btn btn-next-competence">Eerste competentie <img src="/icons/arrow_downward-24px.svg" /></button>
    </div>
  </div>
</template>

<script>
import AppHelper from '@/helpers/AppHelper'

export default {
  props: ['category','firstCompetenceIndex','nr'],
  data() {
    return {
      iconUrl: this.$parent.getIconUrl(this.category.title)
    }
  },
  methods: {
    scrollToCompetence(index) {
      AppHelper.scrollToCompetence(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 100px;
}

.inner {
  background: #0093d8;
  border-radius: 3px;
  color: #FFF;
  padding: 30px;
  margin-top: auto;
}

.icon {
  width: 200px;
}

h2 {
  font-size: 50px;
  margin-bottom: 30px; 
  text-transform: uppercase;
}

p {
  font-size: 20px;
  max-width: 36em;
}

.btn-next-competence {
  margin-top: 25px;
}
</style>