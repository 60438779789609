<template>
  <div class="spinner">
    <div class="svg-holder" v-for="n in 5" :key="n">
      <svg class="shadow-svg" viewBox="-50 0 340 60">
        <ellipse class="shadow" cx="120" cy="30" rx="0" ry="0" fill="black" />
      </svg>
      <div class="logo-holder">
        <svg
          class="logo-svg"
          viewBox="-50 0 433.2 500"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <path
            class="logo"
            :fill="color ? color : '#0093d8'"
            d="M166.6,500C214.8,345.7,273,319,316.9,232.9C371.2,126.5,282.5,0,166.6,0C50.7,0-37.9,126.5,16.4,232.9 C60.2,319,118.4,345.7,166.6,500z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['color']
}
</script>

<style lang="scss">
$timing: 500ms infinite ease-in-out;
$delay: 50ms;

.spinner {
  margin: 0 auto;
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .svg-holder {
    width: 30px;
    margin: 0 5px;
    display: flex;
    flex-direction: column-reverse;

    svg {
      width: 100%;
      vertical-align: middle;
    }

    .shadow-svg {
      margin-top: -4px;
    }

    .shadow {
      animation: shadow $timing;
      rx: 50;
      ry: 10;
      opacity: 0.05;
    }

    .logo-holder {
      transform: translate3d(0, -30px, 0);
      animation: jump $timing;
    }

    .logo {
      transform-origin: 50% 100%;
      animation: jump-buldge $timing;
    }

    &:nth-of-type(2) * {
      animation-delay: $delay;
    }
    &:nth-of-type(3) * {
      animation-delay: $delay * 2;
    }
    &:nth-of-type(4) * {
      animation-delay: $delay * 3;
    }
    &:nth-of-type(5) * {
      animation-delay: $delay * 4;
    }
  }
}

@keyframes jump {
  43% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jump-buldge {
  25% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.35, 0.65);
  }
  60% {
    transform: scale(1);
  }
}

@keyframes shadow {
  45% {
    rx: 120;
    ry: 30;
    opacity: 0.15;
  }
}
</style>