<template>
  <div>
    <div v-if="matrixValues && matrixRequirements && matrixExplanation">
      <StatusBar
        :matrix-values="matrixValues"
        :matrix-other-values="matrixOtherValues"
        :valid-competences="validCompetences"
      />

      <StartScreen
        :matrix-explanation="matrixExplanation"
        :name="matrixName"
        :matrix-other-values="matrixOtherValues"
      />

      <div v-for="(value, index) in matrixValues" :key="index">
        <CategoryScreen v-if="index === 0" :category="matrixCategories[0]" :first-competence-index="0" :nr="1" />
        <CategoryScreen v-if="index === 6" :category="matrixCategories[1]" :first-competence-index="6" :nr="2" />
        <CategoryScreen v-if="index === 15" :category="matrixCategories[2]" :first-competence-index="15" :nr="3" />

        <Competence v-model="matrixValues[index]" :requirements="matrixRequirements[index]" :competence-index="index" />
      </div>

      <EndScreen :matrix-other-values="matrixOtherValues.slice(1)" />
    </div>
    <div v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import GapiHelper from '@/helpers/GapiHelper'
import Competence from '@/components/Competence.vue'
import StatusBar from '@/components/StatusBar.vue'
import StartScreen from '@/components/StartScreen.vue'
import EndScreen from '@/components/EndScreen.vue'
import CategoryScreen from '@/components/CategoryScreen.vue'
import Spinner from '@/components/Spinner'
import { REQUIREMENTS_SHEET_ID } from '@/constants'

export default {
  data() {
    return {
      matrixVersion: null,
      matrixValues: null,
      matrixRequirements: null,
      matrixExplanation: null,
      matrixCategories: [],
      matrixOtherValues: null,
      matrixName: '',
      examplesMarkdown: '',
    }
  },

  computed: {
    validCompetences() {
      return this.matrixValues.map((matrixRow) => {
        // should have a niveau & examples filled in
        return !!matrixRow.level && !!matrixRow.examples
      })
    },
  },

  // watch: {
  //   matrixValues: {
  //     handler: function(newValue) {
  //       console.log('changed', newValue)
  //     },
  //     deep: true
  //   }
  // },

  mounted() {
    this.getMatrixValues()
    this.getMatrixRequirements()
    this.getMatrixExplanation()
  },

  methods: {
    formatMatrixValues(rows) {
      let lastCategory = ''
      const newRows = rows.map((row) => {
        if (row[0]) {
          lastCategory = row[0]
          this.matrixCategories.push({
            title: lastCategory.split(/\r?\n/)[0],
            description: lastCategory.split(/\r?\n/)[1],
          })
        }

        return {
          category: lastCategory.split(/\r?\n/)[0], // only keep title of a category
          competence: row[1].split(/\r?\n/)[0], // only keep title of a competence
          competenceSubtitle: row[1].split(/\r?\n/)[1] || '',
          level: row[2] || '',
          examples: row[3] || '',
          comments: row[4] || '',
          iconUrl: this.getIconUrl(lastCategory),
        }
      })

      return newRows
    },

    formatMatrixOtherValues(rows) {
      const newRows = rows.map((row) => {
        const obj = {
          question: row[1].split(/\r?\n/)[0], // only keep title of a competence
          questionSubtitle: row[1].split(/\r?\n/)[1] || '',
          answer: row[2],
        }

        if (row[1].split(/\r?\n/)[2]) {
          obj.questionSubtitle += row[1].split(/\r?\n/)[2]
        }

        return obj
      })

      return newRows
    },

    getIconUrl(category) {
      if (category.indexOf('planeet') > -1) {
        return '/icons/public-24px.svg'
      }

      if (category.indexOf('Happy') > -1) {
        return '/icons/mood-24px.svg'
      }

      return '/icons/military_tech-24px.svg'
    },

    async getMatrixValues() {
      const obj = await GapiHelper.getMatrixValues(this.$route.params.sheetId, 'Niveaumatrix!A1:E')
      this.matrixName = obj.values[0][1]
      console.log(obj.values)
      this.matrixVersion = obj.values[0][4]

      const othervalues = [obj.values[3], obj.values[26], obj.values[27]]

      if (this.matrixVersion !== 'v2020.1') {
        othervalues.push(obj.values[28])
      }

      this.matrixOtherValues = this.formatMatrixOtherValues(othervalues)
      this.matrixValues = this.formatMatrixValues(obj.values.slice(4, 26))

      // compare with last year sheet
      if (this.$route.query.compareSheetId) {
        const { values } = await GapiHelper.getMatrixValues(this.$route.query.compareSheetId, 'Niveaumatrix!C5:D26')

        if (this.matrixValues) {
          values.forEach((value, index) => {
            this.$set(this.matrixValues[index], 'prevLevel', value[0])
          })
        }
      }
    },

    async getMatrixRequirements() {
      const obj = await GapiHelper.getMatrixValues(REQUIREMENTS_SHEET_ID, 'Niveaumatrix!E3:I')
      this.matrixRequirements = obj.values
    },

    async getMatrixExplanation() {
      const obj = await GapiHelper.getMatrixValues(REQUIREMENTS_SHEET_ID, 'Uitleg!B2:B12')
      this.matrixExplanation = obj.values
    },
  },

  components: {
    CategoryScreen,
    Competence,
    StartScreen,
    EndScreen,
    StatusBar,
    Spinner,
  },
}
</script>

<style scoped lang="scss">
.start {
  min-height: 100vh;
}
</style>
