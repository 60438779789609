<template>
  <header class="statusbar">
    <div class="inner">
      <img src="/images/logo-nm.svg" class="logo" alt="logo" />

      <ul :class="{ 'all-valid': allCompetencesValid }" ref="list">
        <li id="scroll-to-question1">
          <button @click="scrollToEl('question1')" aria-label="Hoe gaat het met je?" data-balloon-pos="down">
            <img v-if="matrixOtherValues[0].answer" src="/icons/check_circle-24px.svg" alt="Checkmark" />
            <img v-else src="/icons/question_answer-24px.svg" alt="Icoon" class="non-valid-icon" />
          </button>
        </li>

        <li v-for="(value, index) in matrixValues" :key="index" :id="`scroll-to-competence${index}`">
          <button @click="scrollToCompetence(index)" :aria-label="value.competence" data-balloon-pos="down">
            <img v-if="validCompetences[index]" src="/icons/check_circle-24px.svg" alt="Checkmark" />
            <img v-else :src="value.iconUrl" alt="Icoon" class="non-valid-icon" />
            <span v-if="value.level > value.prevLevel">+⭐</span>
          </button>
        </li>

        <li
          :id="`scroll-to-question${index + 2}`"
          v-for="(matrixOtherValue, index) in endScreenMatrixOtherValues"
          :key="`shortcut-${index}`"
        >
          <button
            @click="scrollToEl(`question${index + 2}`)"
            :aria-label="matrixOtherValue.question"
            data-balloon-pos="down"
          >
            <img v-if="matrixOtherValue.answer" src="/icons/check_circle-24px.svg" alt="Checkmark" />
            <img v-else src="/icons/question_answer-24px.svg" alt="Icoon" class="non-valid-icon" />
          </button>
        </li>

        <span class="indicator" ref="indicator"></span>
      </ul>

      <div class="save-status">
        <span v-if="hasSaveError" style="color: #d54546"> Error tijdens het opslaan! </span>
        <span v-else-if="isSaving"> Opslaan... </span>
        <span v-else style="color: #679323">
          Opgeslagen in
          <a
            :href="`https://docs.google.com/spreadsheets/d/${this.$route.params.sheetId}/edit`"
            target="_blank"
            rel="noopener"
            >je Drive</a
          >!
        </span>
      </div>
    </div>
  </header>
</template>

<script>
import AppHelper from '@/helpers/AppHelper'
import { mapState } from 'vuex'

export default {
  props: ['matrixValues', 'matrixOtherValues', 'validCompetences'],
  computed: {
    ...mapState(['isSaving', 'hasSaveError']),
    allCompetencesValid() {
      return (
        this.validCompetences.every((c) => {
          return c
        }) &&
        this.matrixOtherValues.every((o) => {
          return !!o.answer
        })
      )
    },
    endScreenMatrixOtherValues() {
      return this.matrixOtherValues.slice(1)
    },
  },
  mounted() {
    this.updateActiveItemStatus()
  },
  methods: {
    scrollToCompetence(index) {
      AppHelper.scrollToCompetence(index)
    },
    scrollToEl(id) {
      const el = document.getElementById(id)
      if (el) {
        AppHelper.scrollToEl(el)
      }
    },
    updateActiveItemStatus() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute('id')

          if (entry.intersectionRatio > 0) {
            requestAnimationFrame(() => {
              const button = document.getElementById(`scroll-to-${id}`)
              if (!button) {
                return
              }

              const { left } = button.getBoundingClientRect()
              const { left: leftOfList } = this.$refs.list.getBoundingClientRect()

              this.$refs.indicator.style.transform = `translateX(${left - leftOfList}px)`
            })
          }
        })
      })

      document.querySelectorAll('section[id]').forEach((section) => {
        observer.observe(section)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$height: 60px;

.statusbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $height;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  z-index: 1;
}

.inner {
  display: flex;
  height: 100%;
}

ul {
  display: flex;
  list-style: none;
  margin: auto;
  padding: 0;
  position: relative;

  &.all-valid {
    &:after {
      content: '🎉';
      position: absolute;
      right: -40px;
      top: -12px;
      font-size: 30px;
    }
  }
}

li {
  padding: 0 1px;

  img {
    display: block;
  }

  button {
    background: transparent;
    display: block;
    padding: 0;
  }
}

li:nth-child(1),
li:nth-child(7),
li:nth-child(16),
li:nth-child(23) {
  margin-right: 20px;
}

.non-valid-icon {
  filter: invert(1);
  opacity: 0.8;
}

.logo,
.save-status {
  position: absolute;
}

.logo {
  width: auto;
  height: 40px;
  left: 20px;
  top: 10px;
}

.save-status {
  font-size: 13px;
  position: absolute;
  right: 20px;
  line-height: $height;

  a {
    color: inherit;
  }
}

$font-size: 13px;
$balloon-move: 4px;

// tooltip code
li button {
  position: relative;

  &:before {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #41453b;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-out 0.3s;
    content: '';
    position: absolute;
    z-index: 10;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: #41453b;
    left: 50%;
    top: 100%;
    margin-top: -1px;
    transform: translate(-50%, -$balloon-move);
    will-change: transform;
  }

  &:after {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-out 0.3s;
    font-size: $font-size;
    background: #41453b;
    border-radius: 3px;
    color: #fff;
    content: attr(aria-label);
    padding: 5px 10px;
    position: absolute;
    white-space: nowrap;
    z-index: 10;
    left: 50%;
    margin-top: 10px;
    top: 100%;
    transform: translate(-50%, -$balloon-move);
    will-change: transform;
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    &:after {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

// level up code
li button span {
  position: absolute;
  top: -80%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-size: 12px;
  margin-left: -1px;
  color: #84bb26;
  font-weight: bold;
}

// indicator
.indicator {
  width: 26px;
  height: 2px;
  background: #84bb26;
  position: absolute;
  bottom: -8px;
  left: 0;
  will-change: transform;
  transition: all 0.1s ease-in-out;
}
</style>
