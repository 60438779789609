<template>
  <div class="container container-fullscreen">
    <img src="/images/logo-nm.svg" class="logo" alt="logo" />

    <div class="hero">
      <h1>Stap 1: Toestemming geven</h1>
      <p class="p-large">
        Om jouw persoonlijke Niveaumatrix te kunnen lezen én schrijven is er toegang nodig tot je Drive.
        En wees gerust... de Reader kan alleen bij de bestanden waarvan het ID bekend is.
      </p>

      <button v-on:click="handleSignoutClick" v-if="isLoggedIn">Sign Out</button>
      <button v-on:click="handleAuthClick" class="btn" v-else>Authoriseer</button>
    </div>
  </div>
</template>

<script>
import GapiHelper from '@/helpers/GapiHelper'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'isLoggedIn'
    ]),
  },

  watch: {
    isLoggedIn(val) {
      if (val) {
        this.$router.push({ name: 'Form' })
      }
    }
  },

  mounted() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'Form' })
    }
  },

  methods: {
    handleAuthClick() {
      GapiHelper.signIn()
    },

    handleSignoutClick() {
      GapiHelper.signOut()
    }
  },
}
</script>
