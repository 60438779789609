<template>
  <div class="container container-fullscreen">
    <div v-if="gapiIsInitialized">
      <div v-if="isLoggedIn">
        <Compentences />
      </div>
      <div v-else>
        <p class="p-large">Je bent nog niet ingelogd...</p>
        <router-link :to="{ name: 'Authorize' }" class="btn">Authorize</router-link>
      </div>
    </div>
    <div v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import Compentences from '@/components/Competences.vue'
import { mapState } from 'vuex'
import Spinner from '@/components/Spinner'

export default {
  computed: {
    ...mapState(['isLoggedIn', 'gapiIsInitialized']),
  },

  components: {
    Compentences,
    Spinner,
  },
}
</script>
